//AWS-AMPLIFY
import { API } from 'aws-amplify';
import {
    createPost,
    deletePost,
    updatePost,
    //
    createUser
} from '../../graphql/mutations';
import { fetchGetAllUsers } from '../queries';


export const handleCreatePost = async ({ title, author, description, latitude, longitude, location, city, country, massageType }) => {
    try {
        await API.graphql({
            query: createPost,
            variables: {
                title,
                author,
                description,
                //GPS
                latitude,
                longitude,
                location,
                city,
                country,
                massageType
            }
        });
    }
    catch (error) {
        console.log('error createPost:', error);
    }
}
export const handleDeletePost = async (id) => {
    try {
        await API.graphql({
            query: deletePost,
            variables: {
                id
            }
        });
    }
    catch (error) {
        console.log('error deletePost:', error);
    }
}
export const handleUpdatePost = async ({ id, title, author, description, latitude, longitude, location, city, country,massageType }) => {
    try {
        await API.graphql({
            query: updatePost,
            variables: {
                id,
                title,
                author,
                description,
                //GPS
                latitude,
                longitude,
                location,
                city,
                country,
                massageType
            }
        });
    }
    catch (error) {
        console.log('error updatePost:', error);
    }
}

export const checkUserExists = async(username) =>{
    const existsUser = await fetchGetAllUsers()
    if(existsUser.length && existsUser[0]?.username === username){
        return true
    }
    else{
        return false
    }
}

export const handleCreateUser = async ({ username, cognitoUsername, role }) => {

    try {
       const userExists = await checkUserExists(username)
       if(userExists){
        throw new Error('User already exists')
       } 
        await API.graphql({
            query: createUser,
            variables: {
                username,
                cognitoUsername,
                role
            }
        });
    }
    catch (error) {
        console.log('error createUser:', error);
        throw new Error(error)
    }
}