/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $title: String!
    $description: String!
    $author: String!
    $latitude: String!
    $longitude: String!
    $location: String!
    $city: String!
    $country: String!
    $massageType: [String!]!
  ) {
    createPost(
      title: $title
      description: $description
      author: $author
      latitude: $latitude
      longitude: $longitude
      location: $location
      city: $city
      country: $country
      massageType: $massageType
    ) {
      id
      title
      description
      author
      latitude
      longitude
      location
      city
      country
      massageType
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost($id: ID!) {
    deletePost(id: $id)
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $id: ID!
    $title: String!
    $description: String!
    $author: String!
    $latitude: String!
    $longitude: String!
    $location: String!
    $city: String!
    $country: String!
    $massageType: [String!]!
  ) {
    updatePost(
      id: $id
      title: $title
      description: $description
      author: $author
      latitude: $latitude
      longitude: $longitude
      location: $location
      city: $city
      country: $country
      massageType: $massageType
    ) {
      id
      title
      description
      author
      latitude
      longitude
      location
      city
      country
      massageType
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($cognitoUsername: String!, $role: Role!, $username: String!) {
    createUser(cognitoUsername: $cognitoUsername, role: $role, username: $username) {
      cognitoUsername
      role
      username
    }
  }
`;

